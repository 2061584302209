

.bottom-padding {
    margin-bottom: 70px;
  }


  .bg-teal {
    background: #1abc9c
}

.bg-blue {

  background: #34495e

}