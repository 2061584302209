
.ExampleWork section {

  font-size: 2.2rem;
  min-height: 6vh;
  padding-top: 5rem;
  padding-bottom: 1rem; 
  width: 100%;
  text-align: left;
  padding-left: 3%;
}

.ExampleWork a {

 color: #fff; 
 margin: 0 15px;

}

.left-align {

  padding-top: 2rem;
  width: 100%;
  text-align: left;
  padding-left: 3.5rem;
}

.ExampleWork p {
  font-size: 2.8rem;
  padding-left: 3%;
  padding-top: 5rem;
  width: 100%;
  text-align: left;
}

.floatcontainer {
  border: .2rem solid #ececec;
  min-height: 40rem;
  border-radius: 25px;
  background-color: #e9ecef;
  width: 90%;
  padding-left: 3%;
  padding-right: 3%;
}
